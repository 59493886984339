@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Thin.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Thin_Italic.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Light_Italic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Regular_Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Medium_Italic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Bold_Italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: "Cera Pro";
  src: url("../fonts/Cera_Pro_Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
