@layer components {
  body {
    @apply text-slate-600 text-lg leading-relaxed;
  }

  h1,
  .h1 {
    @apply text-5xl font-bold text-primary;
  }

  h2,
  .h2 {
    @apply text-4xl font-semibold;
  }

  h3,
  .h3 {
    @apply text-2xl font-semibold;
  }
}
