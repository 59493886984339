@layer components {
  .btn {
    @apply text-primary bg-white font-normal border border-primary border-solid py-2 px-4 focus:outline-none hover:bg-gray-lightest rounded text-base no-underline hover:no-underline;
  }

  .btn-primary {
    @apply text-white bg-primary border-0 focus:outline-none hover:bg-primary-dark font-semibold;
  }

  .btn-link {
    @apply text-primary bg-white font-normal py-2 px-4 focus:outline-none hover:bg-gray-lightest text-base no-underline;
  }
}
