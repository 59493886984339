@layer components {
  .scroll-snap-y {
    scroll-snap-type: y mandatory;
    height: calc(100vh - 10rem);
  }

  parallax-section {
    height: calc(100vh - 10rem);
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    max-width: 100%;
  }

  parallax-section h2 {
    @apply m-0 font-semibold font-serif tracking-tight absolute text-lg p-2 bg-dark text-white shadow;
    @apply top-32 md:top-64;
    max-width: 250px;
    &.top {
      @apply top-32 md:top-16;
    }
  }
}
