@import "jit-refresh.css"; /* triggers frontend rebuilds */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap&family=Inter&display=swap"); */
@import "fonts.css";
@import "components/burger.css";
@import "components/button.css";
@import "components/navbar.css";
@import "components/parallax.css";
@import "components/typography.css";
